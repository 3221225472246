<template>
  <v-layout column>
    <DefaultSubtitle class=" mb-4 ml-4">
      Informacje dodatkowe
    </DefaultSubtitle>
    <v-layout column class="mx-4">
      <InfoSwitch
        label="Zrealizowano obowiązek informacyjny zgodnie z RODO."
        info="Przesuń suwak w prawo, jeśli spełniłeś obowiązek informacyjny o przetwarzaniu danych osobowych lub masz zgodę od pacjenta."
        :value.sync="value.confirm_rules"
        :autosave="autosave"
        :syncId="syncId"
      ></InfoSwitch>
      <InfoSwitch
        class="mt-4 mb-1"
        label="Pacjent wyraził zgodę na świadczenia zdrowotne udzielane przez terapeutów."
        info="Przesuń suwak w prawo, jeśli dołączyłeś załącznik ze zgodą pacjenta na zabieg w zakładce 'zdjęcia i pliki'."
        :value.sync="value.health_benefits"
        :autosave="autosave"
        :syncId="syncId"
      ></InfoSwitch>
      <UnderageSwitchWithAddContent
        class="mt-4"
        :patient.sync="value"
        :autosave="autosave"
        :syncId="syncId"
      ></UnderageSwitchWithAddContent>
      <IncapacitatedSwitchWithAddContent
        class="mt-4"
        :patient.sync="value"
        :autosave="autosave"
        :syncId="syncId"
      ></IncapacitatedSwitchWithAddContent>
      <AuthPersonSwitchWithAddContent
        class="mt-4"
        :patient.sync="value"
        :autosave="autosave"
        :syncId="syncId"
      ></AuthPersonSwitchWithAddContent>
      <NoPeselSwitchWithAddContent
        class="mt-4"
        :patient.sync="value"
        :autosave="autosave"
        :syncId="syncId"
      ></NoPeselSwitchWithAddContent>
      <InfoSwitch
        class="my-4"
        label="Pacjent nie żyje."
        :value.sync="value.died"
        :autosave="autosave"
        :syncId="syncId"
      ></InfoSwitch>
    </v-layout>
  </v-layout>
</template>

<script>
import ProxyModificationMixin from "@/mixins/ProxyModificationMixin";

export default {
  mixins: [ProxyModificationMixin],
  props: {
    value: {},
  },
  components: {
    IncapacitatedSwitchWithAddContent: () =>
      import("@/components/controls/IncapacitatedSwitchWithAddContent"),
    UnderageSwitchWithAddContent: () =>
      import("@/components/controls/UnderageSwitchWithAddContent"),
    AuthPersonSwitchWithAddContent: () =>
      import("@/components/controls/AuthPersonSwitchWithAddContent"),
    NoPeselSwitchWithAddContent: () =>
      import("@/components/controls/NoPeselSwitchWithAddContent"),
    InfoSwitch: () => import("@/components/controls/InfoSwitch"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle")
  },
};
</script>